import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import Header from '../components/Header/Header'
import About from '../components/About/About'
import Contact from '../components/Contact/Contact'
import Videos from '../components/Videos/Videos'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column !important',
    backgroundColor: '#1d9ce4',
    height: '100vh !important',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar <---copypasted from materalui but it works
  toolbar: {
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: 'linear-gradient(45deg, #741bc3, #ee44a7)',
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  buttonLink: {
    '&:first-child': {
      marginTop: '24px',
    },
    '&:hover': {
      border: '1px solid #ddb712',
      backgroundColor: '#ddb712',
    },
    marginBottom: '16px',
    borderRadius: '4px !important',
    padding: '4px 16px',
    backgroundColor: '#fcd432',
    borderColor: '#fcd432',
    color: 'white',
    fontWeight: '900',
    fontSize: '32px',
    textShadow: 'black 1px 0 2px',
    fontFamily: 'Fredoka One'
  },
  arrowIcon: {
    color: 'white',
  }
}));

function App(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  library.add(fab);
  const location = useLocation();

  const handleDrawerToggle = () => {
    if (isMobile) {
      setMobileOpen(!mobileOpen)
    } else {
      return null;
    }
  };

  const Buttons = (
    <div className={classes.buttonGroupContainer}>
      <Hidden smDown implementation="css">
        <div className={classes.toolbar} />
      </Hidden>
      <ButtonGroup orientation="vertical" >
        {location.pathname !== '/' ? (
          <Button
            color="primary"
            className={classes.buttonLink}
            onClick={handleDrawerToggle}
            component={RouterLink} to="/"
          >
            Home
          </Button>
        ) : null}
        {location.pathname !== '/about' ? (
          <Button
            color="primary"
            className={classes.buttonLink}
            onClick={handleDrawerToggle}
            component={RouterLink} to="/about"
          >
            About
          </Button>
        ) : null}
        {location.pathname !== '/videos' ? (
          <Button color="primary"
            className={classes.buttonLink}
            onClick={handleDrawerToggle}
            component={RouterLink} to="/videos"
          >
            Episodes
          </Button>
        ) : null}
        {location.pathname !== '/contact' ? (
          <Button color="primary"
            className={classes.buttonLink}
            onClick={handleDrawerToggle}
            component={RouterLink} to="/contact"
          >
            Contact
          </Button>
        ) : null}
      </ButtonGroup>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      { isMobile ? (
        <AppBar
          position="fixed"
          className={classes.appBar}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      ) : null}
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="persistent"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerToggle}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon className={classes.arrowIcon}/> : <ChevronRightIcon className={classes.arrowIcon}/>}
              </IconButton>
            </div>
            {Buttons}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            anchor="right"
          >
            {Buttons}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/videos">
              <Videos />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <div className="appHeader">
                <Header />
              </div>
            </Route>
        </Switch>
      </main>
    </div>
  );
}

 export default function AppWrapper() { // this wrapper is need to prevent issues with useLocation()
  return (
    <Router>
      <App />
    </Router>
  )
}
