import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#1d9ce4',
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  footerLogo: {
    margin: '8px 16px',
    color: 'black',
    fontSize: '5vw',
    [theme.breakpoints.down('md')]: {
      fontSize: '7vw',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5vw',
    },
  }
}))

export default function Footer() {
  const classes = useStyles();
  // all links subject to change
  return (
    <div className={classes.footer}>
      <a href="https://www.twitch.tv/mrtylercoe" target="_blank" rel="noopener noreferrer" >
        <FontAwesomeIcon icon={["fab", "twitch"]} size="10x" className={classes.footerLogo} />
      </a>
      <a href="https://www.youtube.com/channel/UCAN93Km28w50Ta-7Id4Piag" target="_blank" rel="noopener noreferrer" >
        <FontAwesomeIcon icon={["fab", "youtube"]} size="10x" className={classes.footerLogo} />
      </a>
      <a href="https://twitter.com/howarewe2day" target="_blank" rel="noopener noreferrer" >
      </a>
      <a href="https://www.facebook.com/How-Are-We-Today-100636585559668" target="_blank" rel="noopener noreferrer" >
        <FontAwesomeIcon icon={["fab", "facebook"]} size="10x" className={classes.footerLogo} />
      </a>
      <a href="https://www.instagram.com/imtylercoe/" target="_blank" rel="noopener noreferrer" >
        <FontAwesomeIcon icon={["fab", "instagram"]} size="10x" className={classes.footerLogo} />
      </a>
      <a href="https://open.spotify.com/show/5QTUApv5MiNxowYqoci98q?si=m3jPaTU1RIOmV0W5p1flqA&nd=1" target="_blank" rel="noopener noreferrer" >
        <FontAwesomeIcon icon={["fab", "spotify"]} size="10x" className={classes.footerLogo} />
      </a>
      <a href="https://podcasts.apple.com/us/podcast/how-are-we-today/id1575934042" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={["fab", "itunes"]} size="10x" className={classes.footerLogo} /> 
      </a>
    </div>
  )
}
