import React from 'react';
import mainscreen_website from '../../images/mainscreen_website.png'
import Footer from '../Footer/Footer'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center'
  },
  headerLogo: {
    height: 'auto',
    pointerEvents: 'none',
    width: 'auto',
    maxHeight: '100vh',
    maxWidth: '100%',
  },
}));

export default function Header() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={mainscreen_website} alt="logo" className={classes.headerLogo} />
      <Footer />
    </div>
  )
}
