import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import how_are_we_today_short_square_logo from "../../images/how_are_we_today_short_square_logo.png"
import Footer from '../Footer/Footer'

const useStyles = makeStyles((theme) => ({
  contactPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1d9ce4',
    maxWidth: '100%',
    maxHeight: '100vh',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    maxWidth: '100%',
    maxHeight: '100vh',
    marginBottom: '80px',
  },
  logo: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100vh',
    maxWidth: '100%',
  },
  contactText: {
    fontSize: '48px',
  },
  email: {
    textAlign: 'center',
    marginTop: '32px',
    marginBottom: '32px',
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
}));

export default function Contact() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.contactPageContainer}>
        <div className={classes.logoContainer}>
          <img src={how_are_we_today_short_square_logo} className={classes.logo} alt="how_are_we_today_logo"/>
          <h1 className={classes.contactText}>
            Contact us!
          </h1>
          <h2 className={classes.email}>
            Email: howarewetodayshow@gmail.com
          </h2>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}
