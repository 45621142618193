import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import how_are_we_today_default from "../../images/how_are_we_today_default.png"
import profile_how_are_we_today from "../../images/profile_how_are_we_today.png"

const useStyles = makeStyles((theme) => ({
  aboutContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row !important',
    },
    height: '100%',
  },
  aboutTextLeft: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '16px 32px',
    backgroundColor: 'white',
  },
  aboutTextRight: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '16px 32px',
    color: 'white',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
  },
  howAreWeTodayImage: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100vh',
    maxWidth: '100%',
  },
  thumbsUpGuyContainer: {
    position: 'relative',
    maxHeight: '100vh',
    maxWidth: '100%',
    margin: '0px 32px',
  },
  thumbsUpGuy: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100vh',
    maxWidth: '75%',
  },
  leftSideContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: 'white',
  },
  rightSideContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: '#aa8ed6',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
}));

export default function About() {
  const classes = useStyles();

  const leftSideText = `is a mental health show for those affected by mental illness.
    Whether it be those that suffer from a form of it or those
    that know someone that does, it's a place for people looking
    to learn, or folks simply needing a place to talk.
    This show aims to createe a truly unique community with programming, lessons
    and activities for any and all impacted by mental health issues.`;

  const rightSideText = `Led by medical professionals and joined by special guests, this show is a positive,
    raw, empathetic and humorous program that hopes to build a safe communal space for those
    of us who can't find an outlet to discuss their mental health issues.`;

  return (
    <React.Fragment>
      <div className={classes.aboutContainer}>
        <div className={classes.leftSideContainer}>
          <div className={classes.logoContainer}>
            <img src={how_are_we_today_default} alt="logo" className={classes.howAreWeTodayImage} />
          </div>
          <div className={classes.aboutTextLeft}>
            <Hidden mdUp implementation="css"> 
              <h2>
                <i>"How Are We Today"</i>&nbsp;
                {leftSideText}
              </h2>
            </Hidden>
            <Hidden smDown implementation="css"> 
              <h1>
                <i>"How Are We Today"</i>&nbsp;
                {leftSideText}
              </h1>
            </Hidden>
          </div>
        </div>
        <div className={classes.rightSideContainer}>
          <div className={classes.aboutTextRight}>
            <Hidden mdUp implementation="css">
              <h2>
                {rightSideText}
              </h2>
            </Hidden>
            <Hidden smDown implementation="css"> 
              <h1>
                {rightSideText}
              </h1>
            </Hidden>
          </div>
          <div className={classes.thumbsUpGuyContainer} >
            <img src={profile_how_are_we_today} className={classes.thumbsUpGuy} alt="thumbs_up_guy" />
          </div>
        </div>
      </div>
      {/* <div className={classes.footer}>
        <Footer />
      </div> */}
    </React.Fragment>
  )
}
