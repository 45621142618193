import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
// import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPlayer from 'react-player'
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../Footer/Footer'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  videosContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#1d9ce4',
    textAlign: 'center',
  },
  playerWrapper: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
    position: 'relative !important',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
    maxWidth: '100vw',
    maxHeight: '100vh',
    marginBottom: '3rem',
    padding: '0 1rem',
  },
  reactPlayer: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  mainText: {
    color: 'white',
    fontFamily: 'Fredoka One',
    fontSize: '3rem',
  },
}));

// export default function Videos() {
//   const classes = useStyles();
//   const [embeddedYoutubeVideo, setEmbeddedYoutubeVideo] = useState([]);
//   const [embeddedTwitchVideo, setEmbeddedTwitchVideo] = useState([]);
//   const [isYoutubeLoading, setIsYoutubeLoading] = useState(true);
//   const [isTwitchLoading, setIsTwitchLoading] = useState(true);
//   // eslint-disable-next-line
//   const [isError, setIsError] = useState(false);
//   const [twitchToken, setTwitchToken] = useState([])

//   useEffect(() => {
//     const fetchYoutubeVideos = async () => {
//       // TODO: remove youtube api key somehow
//       await fetch(`https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${process.env.REACT_APP_YOUTUBE_PLAYLIST_ID}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`)
//         .then(response => response.json())
//         .then(response => createVideoData('youtube', response.items[0]))
//         .catch(error => {
//           console.log(error);
//           setIsError(true);
//         });
//     }
//     fetchYoutubeVideos();
//   }, []);

//   useEffect(() => {
//     const fetchTwitchVideos = async () => {
//       if (!twitchToken.length) {
//         await getTwitchToken();
//       }
//       if (twitchToken.length > 0 && embeddedTwitchVideo.length === 0) {
//         await fetch(`https://api.twitch.tv/helix/videos?user_id=${process.env.REACT_APP_TWITCH_USER_ID}&type=all`, {
//             headers: {
//               'Client-ID': process.env.REACT_APP_TWITCH_CLIENT_ID,
//               'Authorization': `Bearer ${twitchToken}`,
//             }
//           })
//           .then(response => response.json())
//           .then(response => {
//             createVideoData('twitch', response.data[0])
//           })
//           .catch(error => {
//             console.log(error);
//             setIsError(true);
//           });
//       }
//     };
//       fetchTwitchVideos();
//   }, [twitchToken, embeddedTwitchVideo]);

//   async function createVideoData(type, videoItem) {
//     if (type === 'youtube') {
//       const snippet = videoItem.snippet;
//       setEmbeddedYoutubeVideo(snippet)
//       setIsYoutubeLoading(false)
//     }
//     if (type === 'twitch') {
//       const url = videoItem.url;
//       setEmbeddedTwitchVideo(url)
//       setIsTwitchLoading(false)
//     }
//   }

//   // TODO: save tokens somewhere and implement a function to refresh them to reduce number of API calls?
//   // TODO: change to use implicit flow and remove client secret and other stuff that shouldnt be exposed

//   async function getTwitchToken() {
//     await fetch(`https://id.twitch.tv/oauth2/token?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&client_secret=${process.env.REACT_APP_TWITCH_CLIENT_SECRET}&grant_type=client_credentials`, {method: 'POST'})
//     .then(response => response.json())
//     .then(response => {
//       setTwitchToken(response.access_token)
//     })
//     .catch(error => {
//       console.log(error);
//       setIsError(true);
//     });
//   }

//   return (
//     <div className={classes.videosContainer}>
//       { isYoutubeLoading ? <CircularProgress className='loading' color='secondary' /> : null }
//       { embeddedYoutubeVideo.resourceId ? (
//         <React.Fragment>
//           <h2>
//             Episodes on Youtube every Tuesday
//           </h2>
//             <h3>
//               Start with the first episode:
//             </h3>
//           <div className={classes.playerWrapper}>
//             <ReactPlayer
//               url={`https://www.youtube.com/watch?v=${embeddedYoutubeVideo.resourceId.videoId}`}
//               controls
//               className={classes.reactPlayer}
//             />
//           </div>
//         </React.Fragment>
//       ) : null }
//       { isTwitchLoading ? <CircularProgress className='loading' color='secondary' /> : null }
//       { embeddedTwitchVideo.length > 0 && embeddedTwitchVideo.length > 0 ? (
//         <React.Fragment>
//           <h3>
//             Live every Monday on Twitch at 7PM CST:
//           </h3>
//           <div className={classes.playerWrapper}>
//             <ReactPlayer
//               url={embeddedTwitchVideo}
//               controls
//               className={classes.reactPlayer}
//             />
//           </div>
//         </React.Fragment>
//       ) : null}
//       <Footer />
//     </div>
//   )
// }

// use below version of the function until security and server is properly setup
export default function Videos() {
  const classes = useStyles();
  // eslint-disable-next-line
  const [isError, setIsError] = useState(false);

  return (
    <div className={classes.videosContainer}>
      <h1 className={classes.mainText}>
        Episodes
      </h1>
      <h2 style={{color: 'white'}}>
        Start with the first episode on YouTube:
      </h2>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          url={'https://www.youtube.com/watch?v=L9JSwz_r-cQ'}
          controls
          className={classes.reactPlayer}
        />
      </div>
      <h2 style={{color: 'white'}}>
        Watch live on Twitch!
      </h2>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          url={'https://www.twitch.tv/videos/1180850823'}
          controls
          className={classes.reactPlayer}
        />
      </div>
      <Footer />
    </div>
  )
}
